import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { USER_LOGIN_FAIL } from '../actions/Types';

const LoginFail = () => {
    const dispatch = useDispatch();
    const { error } = useSelector((state) => state.user);

    useEffect(() => {
        dispatch({ type: USER_LOGIN_FAIL, payload: error })
    }, []);

    return (
        <div>
            <h3 style={{ color: 'red' }}>Login failed.</h3>
            <div>{error}</div>
            <Link to="/">
                Please re-login.
            </Link>
        </div>
    )
};

export default LoginFail;
