import moment from "moment";
import {
  FETCH_PRODUCT_SELECTION,
  SET_ALL_SEARCH_CONFIG,
  SET_COUNTRY,
  SET_DISABLE,
  SET_PRODUCT_CATEGORIES,
  SET_PROGRAM_TYPE,
  SET_RESULTS_LIST,
  SET_SEARCH_KEY,
  SET_SELECTED_CATEGORY,
  SET_SELECTED_SERIES,
  SET_SHOWSERIES
} from "../lib/constants";
import { getAttributeValue } from "../lib/utils";

const productSelector = (
  state = {
    categories: [],
    series: [],
    selectedCategory: null,
    selectedSeries: null,
    columns: [],
    defaultConfigurations: [],
    searchAllConfigs: [],
    countryCode: "",
    programType: "",
    searchValue: "",
    isDisable: true,
    configResultList: [],
    baseUnitConfigResult: [],
    baseUnitColumns: [],
    showPreApproved: false,
    showSeries: false,
    isPartnerPortalFlag: false,
    isCustomBanded: false,
    isShowProgramType: false,
    showConfigResults: false,
    baseUnitValue: "",
    seriesInfo: null,
    userEmail1: "",
    hideServicesForPartners: false,
    page: 0
  },
  action
) => {
  switch (action.type) {
    case SET_PRODUCT_CATEGORIES:
      return {
        ...state,
        categories: action.payload.sortedCategories,
        showSeries: action.payload.showSeries
      };
    case SET_SHOWSERIES:
      return {
        ...state,
        showSeries: action.payload.showSeries,
        defaultConfigurations:
          action.payload.searchListConfigurations === null
            ? action.payload.searchListConfigurations
            : []
      };
    case SET_SELECTED_CATEGORY:
      return {
        ...state,
        selectedCategory: action.payload.category,
        series: action.payload.series
      };
    case SET_SELECTED_SERIES:
      return {
        ...state,
        selectedSeries: action.payload
      };
    case SET_SEARCH_KEY:
      return {
        ...state,
        searchValue: action.payload
      };
    case SET_DISABLE:
      return {
        ...state,
        isDisable: action.payload
      };
    case SET_ALL_SEARCH_CONFIG:
      return {
        ...state,
        searchAllConfigs: action.payload
      };
    case SET_COUNTRY:
      return {
        ...state,
        countryCode: action.payload
      };
    case SET_PROGRAM_TYPE:
      return {
        ...state,
        programType: action.payload
      };
    case SET_RESULTS_LIST:
      const sorted = action.payload
        ? action.payload
            .sort((a, b) => {
              return b.configID - a.configID;
            })
            .sort((a, b) => {
              return (
                moment(b.createdAt).format("YYYYMMDD") -
                moment(a.createdAt).format("YYYYMMDD")
              );
            })
        : [];
      //Added code to display Favorite Configs first
      const favSorted = sorted.filter(ctry => ctry.favorite);
      const nonFavSorted = sorted.filter(ctry => !ctry.favorite);
      const newSorted = [...favSorted, ...nonFavSorted];
      state.columns = [];
      return {
        ...state,
        configResultList: newSorted
      };
    case "SHOW_CONFIG_RESULTS":
      return {
        ...state,
        showConfigResults: action.payload
      };
    case "RESET_SEARCH_CONFIG":
      return {
        ...state,
        configResultList: [],
        searchAllConfigs: [],
        showConfigResults: false,
        searchValue: ""
      };
    case "SET_INITIAL_VALUES":
      const quoteRecord = action.payload.quote || {};
      const defaultCountryCode = action.payload.initialCountryCode;
      const countryCode = quoteRecord.country_quote
        ? quoteRecord.country_quote.value
        : defaultCountryCode
        ? defaultCountryCode
        : "US";
      const partnerID = quoteRecord.locationID_PrimaryReseller_channel_quote
        ? quoteRecord.locationID_PrimaryReseller_channel_quote
        : "";
      let isPartnerPortal =
        action.payload.isPartnerPortalManageConfig === "true";

      const prdRegion = getAttributeValue("attribute-prdRegion");
      const mDCPOrgID = getAttributeValue("attribute-mDCPOrgID");
      const resellerId = getAttributeValue("attribute-resellerId");
      const hideServicesForPartners =
        quoteRecord && quoteRecord.hpQuoteNumber_quote
          ? !(
              window._BM_USER_COMPANY &&
              window._BM_USER_COMPANY.startsWith(
                window.location.hostname.split(".")[0]
              )
            )
          : getAttributeValue("attribute-hideServicesForPartners") === "true";
      const isPreApproved =
        Object.keys(quoteRecord).length > 0
          ? (quoteRecord.region_channel_quote === "AP" ||
              quoteRecord.region_channel_quote === "APJ") &&
            (quoteRecord.mdcpOrgID_quote !== "" || partnerID !== "") &&
            !hideServicesForPartners
          : (prdRegion === "APJ" || prdRegion === "AP") &&
            (mDCPOrgID !== "" || resellerId !== "") &&
            !hideServicesForPartners;

      return {
        ...state,
        countryCode,
        showPreApproved: isPreApproved,
        isPartnerPortalFlag: isPartnerPortal,
        isCustomBanded: action.payload.isCustomBanded !== "false",
        userEmail1: action.payload.userEmail1,
        hideServicesForPartners
      };
    case "SET_RESET_SELECTED":
      return {
        ...state,
        selectedCategory: null,
        selectedSeries: null,
        showSeries: false,
        defaultConfigurations: []
      };
    case "SET_BASEUNIT":
      return {
        ...state,
        baseUnitValue: action.payload.baseunit,
        selectedCategory: action.payload.category,
        selectedSeries: null
      };
    case "SEARCH_DEFAULT_SPC":
      return {
        ...state,
        defaultConfigurations: action.payload.searchListConfigurations,
        isShowProgramType: action.payload.isProgramType
      };
    case "SEARCH_DEFAULT_SPC_BY_BASEUNIT":
      return {
        ...state,
        defaultConfigurations: action.payload.searchListConfigurations,
        isShowProgramType: action.payload.isProgramType,
        selectedCategory: { name: action.payload.selectedCategory },
        selectedSeries: { name: action.payload.selectedSeries },
        series: action.payload.series,
        showSeries: action.payload.showSeries
      };
    case "SET_SERIESINFO":
      return {
        ...state,
        seriesInfo: action.payload
      };
    case "SET_RESET_PAGE":
      return {
        ...state,
        page: action.payload
      };

    case FETCH_PRODUCT_SELECTION:
      return {
        ...state,
        prodSelectionData: action.payload.productSelection
      };

    default:
      return state;
  }
};
export default productSelector;
