import Cookie from "js-cookie";
import {
  CLEAR_USER_INFO,
  RECEIVE_ACCESS_TOKEN,
  TOKEN_RENEW_FAIL,
  TOKEN_RENEW_REQUEST,
  TOKEN_RENEW_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT_FAIL,
  USER_LOGOUT_REQUEST
} from "../actions/Types";

const defaultState = {
  loading: false,
  userInfo: Cookie.getJSON("userInfo") || null,
  token: Cookie.get("access_token"),
  expiresAt: null,
  error: null
};

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case USER_LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        userInfo: payload.data,
        token: payload.token,
        error: null
      };
    case TOKEN_RENEW_SUCCESS:
      return {
        ...state,
        loading: false,
        token: payload
      };
    case USER_LOGIN_FAIL:
      return {
        ...state,
        loading: false,
        token: null,
        userInfo: null,
        expiresAt: null,
        error: payload
      };
    case TOKEN_RENEW_FAIL:
      return {
        ...state,
        loading: false,
        userInfo: null,
        token: null,
        expiresAt: null,
        error: payload
      };
    case TOKEN_RENEW_REQUEST:
      return {
        ...state,
        loading: true,
        token: null,
        expiresAt: null
      };
    case USER_LOGOUT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case USER_LOGOUT_FAIL:
      return {
        ...state,
        loading: false,
        error: payload
      };
    case RECEIVE_ACCESS_TOKEN:
      return {
        ...state,
        loading: false,
        userInfo: payload.user,
        token: payload.token,
        expiresAt: payload.expiresAt,
        error: null
      };
    case CLEAR_USER_INFO:
      return {
        ...state,
        loading: false,
        userInfo: null,
        token: null,
        expiresAt: null,
        error: null
      };
    default:
      return state;
  }
};
